import { useInViewport } from "@mantine/hooks";
import { useEffect, useState } from "preact/hooks";
import { SpotlightGalleryImage } from "spotlight.js";
import { showSpotlight } from "./spotlight";
import { PostImage, PostProps } from "./types";

export const Post = ({ scrollToAfterLoading, onScrollToAfterLoading, post, index, onShow, onMenuItemChanged }: PostProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { ref, inViewport } = useInViewport();

  useEffect(() => {
    setVisible(false);
    if (scrollToAfterLoading) {
      onScrollToAfterLoading();
    }
  }, []);

  useEffect(() => {
    if (inViewport) {
      _onShow();
    }
  }, [inViewport]);

  useEffect(() => {
    // si Preact ne mount/unmount pas un post en cours (grâce au virtual dom)
    // et si c'est le premier d'une liste (index == 0), ca vient d'un changement de menuItem
    // et d'un reload de la liste, du coup je force le recadrage de la map
    if (index == 0) {
      _onShow();
    }
  }, [post]);

  const _onShow = () => {
    setVisible(true);
    onShow();
  };

  const getThumbUrl = (image: PostImage, rowlength: number) => {
    const retina = matchMedia("(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)").matches;
    return getUrl(retina && rowlength === 1 ? image.src : image.thumb);
  };

  const openGallery = (imageindex: number, images: SpotlightGalleryImage[]) => {
    for (const i in images) {
      images[i].title = " ";
      images[i].description = " ";
    }

    showSpotlight(images, {
      index: imageindex + 1,
      control: ["autofit", "zoom", "fullscreen", "page", "prev", "next", "close"],
      theme: "dark",
      autohide: false,
      animation: "fade",
    });
  };

  const getUrl = (url: string) => {
    return "url('" + url.replace(/'/g, "\\'") + "')";
  };

  const rows = (count: number) => {
    switch (count) {
      case 1: {
        return [[0]];
      }
      case 2: {
        return [[0, 1]];
      }
      case 3: {
        return [[0, 1], [2]];
      }
      case 4: {
        return [
          [0, 1],
          [2, 3],
        ];
      }
      case 5: {
        return [[0, 1], [2], [3, 4]];
      }
      case 6: {
        return [
          [0, 1],
          [2, 3],
          [4, 5],
        ];
      }
      case 7: {
        return [[0, 1, 2], [3], [4, 5, 6]];
      }
      case 8: {
        return [
          [0, 1],
          [2, 3],
          [4, 5],
          [6, 7],
        ];
      }
      case 9: {
        return [
          [0, 1, 2],
          [3, 4, 5],
          [6, 7, 8],
        ];
      }
      case 10: {
        return [
          [0, 1, 2],
          [3, 4],
          [5, 6],
          [7, 8, 9],
        ];
      }
      case 11: {
        return [
          [0, 1, 2],
          [3, 4],
          [5, 6, 7],
          [8, 9, 10],
        ];
      }
      case 12: {
        return [
          [0, 1, 2],
          [3, 4, 5],
          [6, 7, 8],
          [9, 10, 11],
        ];
      }
      default: {
        return [[0]];
      }
    }
  };

  return (
    <div className="post" id={post.id} style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <div className="postheader">
        <div className="title" ref={ref}>
          <h3 className="titleelt">{post.title}</h3>
          {post.tags?.map((tag, index) => (
            <div key={index}>
              <a className="taglink" onClick={() => onMenuItemChanged({ name: tag })}>
                {tag}
              </a>
            </div>
          ))}
        </div>
        <div className="date">
          {new Date(post.date * 1000).toLocaleDateString("fr-FR", { weekday: "long", year: "numeric", month: "long", day: "numeric" })}
        </div>

        <p style={{ fontSize: "14px" }}>{post.content}</p>
      </div>
      <div className="postbody">
        {rows(post.images?.length || 0).map((row, index) => (
          <div className="photoset-row" key={index}>
            {row.map((imageindex) => (
              <div key={imageindex} className={row ? "photoset-cell cols-" + row.length : "photoset-cell"}>
                <div
                  onClick={() => openGallery(imageindex, post.images || [])}
                  className="photoset-div-img"
                  style={{ backgroundImage: visible ? getThumbUrl((post.images || [])[imageindex], row.length) : "" }}
                ></div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
