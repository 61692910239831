import Point from "@mapbox/point-geometry";
import {
  LeafletContextInterface,
  createElementObject,
  createPathComponent,
  createTileLayerComponent,
  extendContext,
} from "@react-leaflet/core";
import type { Bounds, Coords, GridLayerOptions, LatLng } from "leaflet";
import { ControlPosition, DomUtil, GridLayer, LayerGroup, LeafletMouseEventHandlerFn, Marker, layerGroup } from "leaflet";
// eslint-disable-next-line prettier/prettier
import "leaflet.markercluster";
// eslint-disable-next-line prettier/prettier
import { MarkerClusterGroup as MCG, MarkerClusterGroupOptions, markerClusterGroup } from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import { PMTiles } from "pmtiles";
import { ReactNode } from "preact/compat";
import { useEffect } from "preact/hooks";
import { labelRules, paintRules } from "protomaps-leaflet/src/default_style/style";
import { DARK, LIGHT } from "protomaps-leaflet/src/default_style/themes";
import { LabelRule, Labelers } from "protomaps-leaflet/src/labeler";
import { PaintRule, paint } from "protomaps-leaflet/src/painter";
import { PreparedTile, SourceOptions, View, sourcesToViews } from "protomaps-leaflet/src/view";
import { LayersControl, TileLayer, useMap } from "react-leaflet";

const timer = (duration: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

// replacement for Promise.allSettled (requires ES2020+)
// this is called for every tile render,
// so ensure font loading failure does not make map rendering fail
type Status = {
  status: string;
  value?: unknown;
  reason: Error;
};

const reflect = (promise: Promise<Status>) => {
  return promise.then(
    (v) => {
      return { status: "fulfilled", value: v };
    },
    (error) => {
      return { status: "rejected", reason: error };
    },
  );
};

type DoneCallback = (error?: Error, tile?: HTMLElement) => void;
type KeyedHtmlCanvasElement = HTMLCanvasElement & { key: string };

export interface LeafletLayerOptions extends GridLayerOptions {
  debug?: string;
  lang?: string;
  tileDelay?: number;
  language?: string[];
  paintRules?: PaintRule[];
  labelRules?: LabelRule[];
  tasks?: Promise<Status>[];
  maxDataZoom?: number;
  url?: PMTiles | string;
  sources?: Record<string, SourceOptions>;
  theme?: string;
  backgroundColor?: string;
}

const leafletLayer = (options: LeafletLayerOptions = {}): unknown => {
  class LeafletLayer extends GridLayer {
    paintRules?: PaintRule[];
    labelRules?: LabelRule[];
    backgroundColor?: string;
    debug?: string;
    lang?: string;
    lastRequestedZ: number | undefined;
    tasks: Promise<Status>[];
    views: Map<string, View>;
    scratch: CanvasRenderingContext2D;
    labelers: Labelers;
    tileSize: number;
    tileDelay: number;
    onTilesInvalidated: (tiles: Set<string>) => void;
    _keyToTileCoords?: (key: string) => Coords;
    _pxBoundsToTileRange?: (bounds: Bounds) => Bounds;
    _getTiledPixelBounds?: (center: LatLng) => Bounds;
    xray: any;
    constructor(options: LeafletLayerOptions = {}) {
      if (options.noWrap && !options.bounds)
        options.bounds = [
          [-90, -180],
          [90, 180],
        ];
      if (options.attribution == null)
        options.attribution =
          '<a href="https://protomaps.com">Protomaps</a> © <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>';
      super(options);

      if (options.theme) {
        const thm = options.theme == "light" ? LIGHT : DARK;
        this.paintRules = paintRules(thm);
        this.labelRules = labelRules(thm);
        this.backgroundColor = thm.background;
      } else {
        this.paintRules = options.paintRules || [];
        this.labelRules = options.labelRules || [];
        this.backgroundColor = options.backgroundColor;
      }

      this.lastRequestedZ = undefined;
      this.tasks = options.tasks || [];

      this.views = sourcesToViews(options);

      this.debug = options.debug;
      const scratch = document.createElement("canvas").getContext("2d");
      this.scratch = scratch!;
      this.onTilesInvalidated = (tiles: Set<string>) => {
        for (const t of tiles) {
          this.rerenderTile(t);
        }
      };
      this.labelers = new Labelers(this.scratch, this.labelRules, 16, this.onTilesInvalidated);
      this.tileSize = 256 * window.devicePixelRatio;
      this.tileDelay = options.tileDelay || 3;
      this.lang = options.lang;
    }

    public async renderTile(
      coords: Coords,
      element: KeyedHtmlCanvasElement,
      key: string,
      done = () => {
        return;
      },
    ) {
      this.lastRequestedZ = coords.z;

      const promises = [];
      for (const [k, v] of this.views) {
        const promise = v.getDisplayTile(coords);
        promises.push({ key: k, promise: promise });
      }
      const tileResponses = await Promise.all(
        promises.map((o) => {
          return o.promise.then(
            (v: PreparedTile) => {
              return { status: "fulfilled", value: v, key: o.key, reason: undefined };
            },
            (error: Error) => {
              return { status: "rejected", reason: error, key: o.key, value: undefined };
            },
          );
        }),
      );

      const preparedTilemap = new Map<string, PreparedTile[]>();
      for (const tileResponse of tileResponses) {
        if (tileResponse.status === "fulfilled") {
          preparedTilemap.set(tileResponse.key, [tileResponse.value as PreparedTile]);
        } else {
          if (tileResponse.reason!.name === "AbortError") {
            // do nothing
          } else {
            console.error(tileResponse.reason);
          }
        }
      }

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      await Promise.all(this.tasks.map(reflect));

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      const layoutTime = this.labelers.add(coords.z, preparedTilemap);

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      const labelData = this.labelers.getIndex(coords.z);

      if (!this._map) return; // the layer has been removed from the map

      const center = this._map.getCenter().wrap();
      const pixelBounds = this._getTiledPixelBounds!(center);
      const tileRange = this._pxBoundsToTileRange!(pixelBounds);
      const tileCenter = tileRange.getCenter();
      const priority = coords.distanceTo(tileCenter) * this.tileDelay;

      await timer(priority);

      if (element.key !== key) return;
      if (this.lastRequestedZ !== coords.z) return;

      const buf = 16;
      const bbox = {
        minX: 256 * coords.x - buf,
        minY: 256 * coords.y - buf,
        maxX: 256 * (coords.x + 1) + buf,
        maxY: 256 * (coords.y + 1) + buf,
      };
      const origin = new Point(256 * coords.x, 256 * coords.y);

      element.width = this.tileSize;
      element.height = this.tileSize;
      const ctx = element.getContext("2d");
      if (!ctx) {
        console.error("Failed to get Canvas context");
        return;
      }
      ctx.setTransform(this.tileSize / 256, 0, 0, this.tileSize / 256, 0, 0);
      ctx.clearRect(0, 0, 256, 256);

      if (this.backgroundColor) {
        ctx.save();
        ctx.fillStyle = this.backgroundColor;
        ctx.fillRect(0, 0, 256, 256);
        ctx.restore();
      }

      let paintingTime = 0;

      const paintRules = this.paintRules;

      paintingTime = paint(
        ctx,
        coords.z,
        preparedTilemap,
        this.xray ? null : labelData || null,
        paintRules!,
        bbox,
        origin,
        false,
        this.debug,
      );

      if (this.debug) {
        ctx.save();
        ctx.fillStyle = this.debug;
        ctx.font = "600 12px sans-serif";
        ctx.fillText(`${coords.z} ${coords.x} ${coords.y}`, 4, 14);

        ctx.font = "12px sans-serif";
        let ypos = 28;
        for (const [k, v] of preparedTilemap) {
          const dt = v[0].dataTile;
          ctx.fillText(`${k + (k ? " " : "") + dt.z} ${dt.x} ${dt.y}`, 4, ypos);
          ypos += 14;
        }

        ctx.font = "600 10px sans-serif";
        if (paintingTime > 8) {
          ctx.fillText(`${paintingTime.toFixed()} ms paint`, 4, ypos);
          ypos += 14;
        }

        if (layoutTime > 8) {
          ctx.fillText(`${layoutTime.toFixed()} ms layout`, 4, ypos);
        }
        ctx.strokeStyle = this.debug;

        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, 256);
        ctx.stroke();

        ctx.lineWidth = 0.5;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(256, 0);
        ctx.stroke();

        ctx.restore();
      }
      done();
    }

    public rerenderTile(key: string) {
      for (const unwrappedK in this._tiles) {
        const wrappedCoord = this._wrapCoords(this._keyToTileCoords!(unwrappedK));
        if (key === this._tileCoordsToKey(wrappedCoord)) {
          this.renderTile(wrappedCoord, this._tiles[unwrappedK].el as KeyedHtmlCanvasElement, key);
        }
      }
    }

    public clearLayout() {
      this.labelers = new Labelers(this.scratch, this.labelRules!, 16, this.onTilesInvalidated);
    }

    public rerenderTiles() {
      for (const unwrappedK in this._tiles) {
        const wrappedCoord = this._wrapCoords(this._keyToTileCoords!(unwrappedK));
        const key = this._tileCoordsToKey(wrappedCoord);
        this.renderTile(wrappedCoord, this._tiles[unwrappedK].el as KeyedHtmlCanvasElement, key);
      }
    }

    public createTile(coords: Coords, showTile: DoneCallback) {
      const element = DomUtil.create("canvas", "leaflet-tile") as KeyedHtmlCanvasElement;
      element.lang = this.lang!;

      const key = this._tileCoordsToKey(coords);
      element.key = key;

      this.renderTile(coords, element, key, () => {
        showTile(undefined, element);
      });

      return element;
    }

    public _removeTile(key: string) {
      const tile = this._tiles[key];
      if (!tile) {
        return;
      }
      const el = tile.el as HTMLElement & {
        removed: boolean;
        key: string | undefined;
        width: number;
        height: number;
      };
      el.removed = true;
      el.key = undefined;
      DomUtil.removeClass(el, "leaflet-tile-loaded");
      el.width = el.height = 0;
      DomUtil.remove(tile.el);
      delete this._tiles[key];
      this.fire("tileunload", {
        tile: tile.el,
        coords: this._keyToTileCoords!(key),
      });
    }
  }
  return new LeafletLayer(options);
};

const CustomMarkerCluster = ({
  markers,
  options,
  disable,
}: {
  markers: Marker[];
  options?: MarkerClusterGroupOptions;
  disable?: boolean;
}) => {
  const map = useMap();

  useEffect(() => {
    let cluster: LayerGroup;
    if (!disable) {
      cluster = markerClusterGroup(options);
      markers && markers.forEach((marker) => marker.addTo(cluster));
    } else {
      cluster = layerGroup(markers);
    }
    map.addLayer(cluster);
    return () => {
      cluster.clearLayers();
      map.removeLayer(cluster);
    };
  }, [markers, map]);

  return null;
};

interface ProtoLeafletGridLayer extends GridLayer, LeafletLayerOptions {
  clearLayout: () => void;
  rerenderTiles: () => void;
}

const LeafletLayer = createTileLayerComponent<ProtoLeafletGridLayer, LeafletLayerOptions>(
  function createTileLayer(options: LeafletLayerOptions, context) {
    const layer = (leafletLayer as (options?: LeafletLayerOptions) => ProtoLeafletGridLayer)(options);
    return createElementObject(layer, context);
  },
  function updateTileLayer(layer, props, prevProps) {
    const { url, theme } = props;
    if ((url != null && url !== prevProps.url) || (theme != null && theme !== prevProps.theme)) {
      const thm = theme == "light" ? LIGHT : DARK;
      layer.paintRules = paintRules(thm);
      layer.labelRules = labelRules(thm);
      layer.backgroundColor = thm.background;
      layer.clearLayout();
      layer.rerenderTiles();
    }
  },
);

const ProtoLayer = ({
  overlays,
  position,
  options,
}: {
  overlays?: ("Satellite" | "JAWG")[];
  position?: ControlPosition | undefined;
  options?: LeafletLayerOptions;
}) => {
  return (
    <LayersControl position={position}>
      <LayersControl.BaseLayer checked name="Carte">
        <LeafletLayer {...options} />
      </LayersControl.BaseLayer>
      {overlays?.map((overlay) => (
        <LayersControl.Overlay key={overlay} name={overlay}>
          {overlay == "Satellite" && (
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution={
                "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              }
            />
          )}
          {overlay == "JAWG" && (
            <TileLayer
              url={
                "https://{s}.tile.jawg.io/jawg-" +
                (options?.theme || "dark") +
                "/{z}/{x}/{y}{r}.png?access-token=SHBWxtwi08qiHUY5DxGtYGKn9MQ2x4fkiT3BncFx82pxcOiyKzjkjKvBLUUPDyw8"
              }
              attribution={'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
            />
          )}
        </LayersControl.Overlay>
      ))}
    </LayersControl>
  );
};

type ClusterType = { [key in string]: any };

type ClusterEvents = {
  onClick?: LeafletMouseEventHandlerFn;
  onDblClick?: LeafletMouseEventHandlerFn;
  onMouseDown?: LeafletMouseEventHandlerFn;
  onMouseUp?: LeafletMouseEventHandlerFn;
  onMouseOver?: LeafletMouseEventHandlerFn;
  onMouseOut?: LeafletMouseEventHandlerFn;
  onContextMenu?: LeafletMouseEventHandlerFn;
};

type MarkerClusterControl = MarkerClusterGroupOptions & {
  children: ReactNode;
} & ClusterEvents;

function getPropsAndEvents(props: MarkerClusterControl) {
  let clusterProps: ClusterType = {};
  let clusterEvents: ClusterType = {};
  const { ...rest } = props;
  // Splitting props and events to different objects
  Object.entries(rest).forEach(([propName, prop]) => {
    if (propName.startsWith("on")) {
      clusterEvents = { ...clusterEvents, [propName]: prop };
    } else {
      clusterProps = { ...clusterProps, [propName]: prop };
    }
  });
  return { clusterProps, clusterEvents };
}

function createMarkerClusterGroup(props: MarkerClusterControl, context: LeafletContextInterface) {
  const { clusterProps, clusterEvents } = getPropsAndEvents(props);
  const markerClusterGroup = new MCG(clusterProps);
  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
    markerClusterGroup.on(clusterEvent, callback);
  });
  return createElementObject(markerClusterGroup, extendContext(context, { layerContainer: markerClusterGroup }));
}

const MarkerClusterGroup = createPathComponent<MCG, MarkerClusterControl>(createMarkerClusterGroup);

export { CustomMarkerCluster, leafletLayer, ProtoLayer, MarkerClusterGroup };
