import { render } from "preact";
import { useState } from "preact/hooks";
import "./app.css";
import { Photoblog } from "./blog";
import { Music } from "./music";
import { Tracks } from "./tracks";
import { MenuItem } from "./types";

//Comportement esbuild quand "type":"module" : https://github.com/evanw/esbuild/pull/1849
//import _MarkerClusterGroup from "react-leaflet-cluster";
//const MarkerClusterGroup = _MarkerClusterGroup["default"] ?? _MarkerClusterGroup;

// "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
//const layer_provider =
//  "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=87220183-92c9-444b-a0d3-20d0f2648d13";
//layer_provider = "https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=SHBWxtwi08qiHUY5DxGtYGKn9MQ2x4fkiT3BncFx82pxcOiyKzjkjKvBLUUPDyw8"
// xijZq0l2bXySH8ZKrMZZ1Rd5njDpapUZGzDB82tGt2Hv5rmzQE4VPNR8hygoQbi3
// 59naIgIUbsIL6hvWL3rBlku4qnzyebH5Zbidzyf5DqHR8zDM331xqCWPpLKF7Cbp

const App = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [pageMenuItem, setPageMenuItem] = useState<MenuItem>();
  const [name, setName] = useState<string>("Photoblog");
  const [menus, setMenus] = useState([]);
  const [checked, setChecked] = useState<boolean>(false);

  const onPageMounted = (name: string, menus: MenuItem[]) => {
    setName(name);
    setMenuItems(menus);
    setPageMenuItem(menus[0]);
    setChecked(false);
  };

  const onMenuItemChanged = (menuItem: MenuItem) => {
    setPageMenuItem(menuItem);
    setChecked(false);
  };

  const showChildren = (menuItem: MenuItem) => {
    if (menuItem.children) {
      setMenuItems([{ name: "←", parents: menuItems }, ...menuItem.children]);
      setChecked(true);
    }
  };

  const showParents = (menuItem: MenuItem) => {
    if (menuItem.parents) {
      setMenuItems(menuItem.parents);
      setChecked(true);
    }
  };

  const showPage = (pg: string) => {
    if (name == pg) {
      // reload
      setPageMenuItem(menus[0]);
      setMenuItems(menus);
    } else {
      setName(pg);
      setMenus([]);
      setPageMenuItem(undefined);
    }
    setChecked(false);
  };

  // https://phosphoricons.com/
  return (
    <div>
      <header className="header">
        <a title="Photos" onClick={() => showPage("Photoblog")} className={(name == "Photoblog" ? "selected_page " : "") + "logo"}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path
              d="M208,208H48a16,16,0,0,1-16-16V80A16,16,0,0,1,48,64H80L96,40h64l16,24h32a16,16,0,0,1,16,16V192A16,16,0,0,1,208,208Z"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
            ></path>
            <circle cx="128" cy="132" r="36" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></circle>
          </svg>
        </a>
        <a title="Vélo" onClick={() => showPage("Tracks")} className={(name == "Tracks" ? "selected_page " : "") + "logo"}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path d="M208,80a16,16,0,0,0-16-16H152l56,96" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></path>
            <circle cx="208" cy="160" r="40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></circle>
            <circle cx="48" cy="160" r="40" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></circle>
            <polyline points="48 64 76 64 132 160" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></polyline>
            <polyline points="170.1 96 94.7 96 48 160" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></polyline>
          </svg>
        </a>
        <a title="Musique" onClick={() => showPage("Music")} className={(name == "Music" ? "selected_page " : "") + "logo"}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <circle cx="180" cy="172" r="28" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></circle>
            <circle cx="52" cy="204" r="28" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></circle>
            <polyline
              points="80 204 80 64 208 32 208 172"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="12"
            ></polyline>
          </svg>
        </a>

        <input className="menu-btn" checked={checked} type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        <ul className="menu">
          {menuItems.map((m, index) => (
            <li key={index} className={pageMenuItem && m && pageMenuItem.name == m.name ? "selected_menu_item" : "menu_item"}>
              <a onClick={() => (m.children ? showChildren(m) : m.parents ? showParents(m) : onMenuItemChanged(m))}>
                {m.name == "Ailleurs" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256">
                    <circle cx="128" cy="128" r="96" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"></circle>
                    <path
                      d="M214.2,170.4,159.9,137a7.2,7.2,0,0,0-3.1-1.1l-22.9-3.1a7.8,7.8,0,0,0-8.3,4.7l-13.7,30.7a8,8,0,0,0,1.4,8.7l18.8,20.3a8.2,8.2,0,0,1,2,7L130.2,224"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="12"
                    ></path>
                    <path
                      d="M65,55.6,56,76.8a8,8,0,0,0-.1,5.9l11.5,30.6a7.9,7.9,0,0,0,5.8,5.1L94.6,123a7.8,7.8,0,0,1,5.5,4.3l3.8,7.9a8.2,8.2,0,0,0,7.2,4.5h13.5"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="12"
                    ></path>
                    <path
                      d="M152.5,35.1l9.3,16.8a8.1,8.1,0,0,1-1.6,9.8L133.3,86a7.2,7.2,0,0,1-1.5,1.1l-12.3,6.8a8.6,8.6,0,0,1-3.8,1H94.3a8.1,8.1,0,0,0-7.4,4.9l-8.3,19.7"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="12"
                    ></path>
                  </svg>
                ) : (
                  ""
                )}
                {m.name}
              </a>
            </li>
          ))}
        </ul>
      </header>
      {name == "Photoblog" && <Photoblog {...{ pageMenuItem, onPageMounted, onMenuItemChanged }}></Photoblog>}
      {name == "Tracks" && <Tracks {...{ pageMenuItem, onPageMounted, onMenuItemChanged }}></Tracks>}
      {name == "Music" && <Music {...{ pageMenuItem, onPageMounted, onMenuItemChanged }}></Music>}
    </div>
  );
};

// on force le scroll à se resetter à chaque lancement
if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

render(<App />, document.body);
